'use client';

import { createContext } from 'react';
import type { CreatorPrivileges } from '@creator-portal/common/types';
import type { JSX, ReactNode } from 'react';
interface ContextProps {
  children: ReactNode;
  value?: CreatorPrivileges | null;
}
export const CreatorPrivilegesContext = createContext<CreatorPrivileges | undefined | null>(undefined);

/** React context for providing CreatorPrivileges */
export function CreatorPrivilegesProvider({
  value,
  children
}: ContextProps): JSX.Element {
  return <CreatorPrivilegesContext.Provider value={value} data-sentry-element="unknown" data-sentry-component="CreatorPrivilegesProvider" data-sentry-source-file="creator-privileges.context.tsx">{children}</CreatorPrivilegesContext.Provider>;
}