'use client';

import { createEpicTheme } from '@epic-ui/theme';

import { alertOverrides } from '@/styles/overrides/alerts';
import { breakpoints } from '@/styles/overrides/breakpoints';
import { commonColors } from '@/styles/overrides/commonColors';
import { darkColors } from '@/styles/overrides/darkColors';
// import { lightColors } from '@/styles/overrides/lightColors';
import { tooltipsOverrides } from '@/styles/overrides/tooltips';
import { typography } from '@/styles/overrides/typography';

const themeMode = 'dark';
const themeDirection = 'ltr';

// TODO: Handle dark/light mode toggle
// TODO: Handle theme direction toggle
export const theme = createEpicTheme({
  direction: themeDirection,
  breakpoints,
  palette: {
    mode: themeMode,
    // ...(themeMode === 'light' ? lightColors : darkColors),
    ...darkColors,
    ...commonColors,
  },
  components: {
    ...tooltipsOverrides,
    ...alertOverrides,
  },
  typography,
  zIndex: {
    modal: 9001,
    tooltip: 10001,
  },
});
