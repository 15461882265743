'use client';

import { createContext } from 'react';
import type { CreatorProgramStatusResponse } from '@creator-portal/common/types';
import type { JSX, ReactNode } from 'react';
interface ContextProps {
  children: ReactNode;
  value?: CreatorProgramStatusResponse;
}
export const CreatorProgramStatusContext = createContext<CreatorProgramStatusResponse | undefined>(undefined);

/** React context for providing CreatorProgramStatusResponse */
export function CreatorProgramStatusProvider({
  value,
  children
}: ContextProps): JSX.Element {
  return <CreatorProgramStatusContext.Provider value={value} data-sentry-element="unknown" data-sentry-component="CreatorProgramStatusProvider" data-sentry-source-file="creator-program-status.context.tsx">{children}</CreatorProgramStatusContext.Provider>;
}