import type { Theme } from '@mui/material/styles';

export enum Breakpoint {
  XXS = 'xxs',
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
  XXL = 'xxl',
}

const keys = Object.values(Breakpoint);

const values = {
  [Breakpoint.XXS]: 0,
  [Breakpoint.XS]: 375,
  [Breakpoint.SM]: 768,
  [Breakpoint.MD]: 1024,
  [Breakpoint.LG]: 1280,
  [Breakpoint.XL]: 1440,
  [Breakpoint.XXL]: 1920,
};

export const breakpoints: {
  keys: typeof keys;
  name: typeof Breakpoint;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars -- ??
  values: { [key in Breakpoint]: number };
} = {
  keys,
  name: Breakpoint,
  values,
};

// DO NOT REMOVE [https://stackoverflow.com/questions/70697666]
declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    [Breakpoint.XXS]: true;
    [Breakpoint.XS]: true;
    [Breakpoint.SM]: true;
    [Breakpoint.MD]: true;
    [Breakpoint.LG]: true;
    [Breakpoint.XL]: true;
    [Breakpoint.XXL]: true;
  }
}

export const MEDIA_QUERY_LANDSCAPE = '@media screen and (orientation: landscape) and (max-height: 612px)';
