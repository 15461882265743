'use client';

import { createContext } from 'react';
import type { ColorModeInterface } from '@/hooks/useColorMode';
import type { JSX, ReactNode } from 'react';
interface ContextProps {
  children: ReactNode;
  value?: ColorModeInterface | null;
}
export const ColorModeContext = createContext<ColorModeInterface | undefined | null>({
  toggleColorMode: () => {}
});

/** React context for providing ColorMode */
export function ColorModeProvider({
  value,
  children
}: ContextProps): JSX.Element {
  return <ColorModeContext.Provider value={value} data-sentry-element="unknown" data-sentry-component="ColorModeProvider" data-sentry-source-file="color-mode.context.tsx">{children}</ColorModeContext.Provider>;
}