'use client';

import { createContext } from 'react';
import type { AuthSession } from '@creator-portal/common/types';
import type { JSX, ReactNode } from 'react';
export interface AuthSessionProviderProps {
  children: ReactNode;
  value?: AuthSession;
}
export const AuthSessionContext = createContext<AuthSession | undefined>?.(undefined);
export function AuthSessionProvider({
  value,
  children
}: AuthSessionProviderProps): JSX.Element {
  if (!AuthSessionContext) {
    throw new Error('React Context is unavailable in React Server Components');
  }
  return <AuthSessionContext.Provider value={value} data-sentry-element="unknown" data-sentry-component="AuthSessionProvider" data-sentry-source-file="auth-session.context.tsx">{children}</AuthSessionContext.Provider>;
}