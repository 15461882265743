'use client';

import { memo } from 'react';
import { createInstance } from 'i18next';
import { I18nextProvider } from 'react-i18next';
import { initTranslations } from '@/app/i18n';
import type { Resource } from 'i18next';
import type { PropsWithChildren } from 'react';
interface TranslationProviderProps {
  locale: string;
  namespaces: string[];
  resources: Resource;
}
export const TranslationProvider = memo<PropsWithChildren<TranslationProviderProps>>(function TranslationProvider({
  children,
  locale,
  namespaces,
  resources
}) {
  const i18n = createInstance();
  void initTranslations(locale, namespaces, i18n, resources);
  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
});