'use client';

import { createContext } from 'react';
import type { PublicRuntimeConfig } from '@/public-runtime-config';
export const PublicRuntimeConfigContext = createContext<PublicRuntimeConfig | undefined>(undefined);
export const PublicRuntimeConfigProvider: React.FC<React.PropsWithChildren<{
  config?: PublicRuntimeConfig;
}>> = ({
  children,
  config
}) => {
  return <PublicRuntimeConfigContext.Provider value={config} data-sentry-element="unknown" data-sentry-component="PublicRuntimeConfigProvider" data-sentry-source-file="public-runtime-config.provider.tsx">{children}</PublicRuntimeConfigContext.Provider>;
};